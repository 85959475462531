import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./userInvite.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON } from "app/shared/Selector/selector.constants";
import { useEffect } from "react";
import {
  API_ENDPOINT_USER_CREATE_BULK,
  API_ENDPOINT_USER_CREATE_BULK_STUDENTS,
} from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
import { API_ENDPOINT_SUBJECT_BULK_CREATE } from "app/scenes/subjects/subjects.constants";
export default function CreateSubjects(props) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedClass, setSelectedClass] = useState("");
  const [subjectList, setSubjectList] = useState([
    {
      id: 1,
      name: "",
      code: "",
    },
  ]);

  const dispatch = useDispatch();

  const addRowInvite = () => {
    const currentId = subjectList[subjectList.length - 1].id;
    setSubjectList([...subjectList, { name: "", code: "", id: currentId + 1 }]);
  };

  const removeRowInvite = (itemId) => {
    setSubjectList(subjectList.filter((item) => item.id !== itemId));
  };

  const handleCreateSubject = async () => {
    const users = [];
    subjectList.forEach((element) => {
      users.push({
        code: element.code,
        name: element.name,
      });
    });

    const response = await apiPost(API_ENDPOINT_SUBJECT_BULK_CREATE, {
      subjects: users,
      classId: selectedClass,
    });

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const handleInput = (rowId, key, data) => {
    let prevData = JSON.parse(JSON.stringify(subjectList));
    prevData[rowId][key] = data;
    setSubjectList(prevData);
  };

  const beforeUpload = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        addParsedEntry(results.data);
      },
    });
  };

  const addParsedEntry = (entries) => {
    let newEntries = [];
    let newId = subjectList.length + 1;
    entries.forEach((element) => {
      newEntries.push({
        name: element[0],
        code: element[1] || null,
        id: newId,
      });
      newId = newId + 1;
    });
    setSubjectList(newEntries);
  };

  return (
    <div className="InviteStudents">
      <div
        className="InviteSelectors"
        style={{
          borderBottom: selectedClass ? "1px solid rgb(235, 230, 230)" : "none",
        }}
      >
        <div className="SelectorContainer">
          <Selector
            placeholder="Year"
            labelKey="value"
            valueKey="key"
            serviceType="STATIC"
            onChange={(data) => setSelectedYear(data)}
            defaultValue={selectedYear}
            staticData={[
              {
                key: "2019",
                value: 2019,
              },
              {
                key: "2020",
                value: 2020,
              },
              {
                key: "2021",
                value: 2021,
              },
              {
                key: "2022",
                value: 2022,
              },
              {
                key: "2023",
                value: 2023,
              },
              {
                key: "2024",
                value: 2024,
              },
            ]}
          />
        </div>

        <div className="SelectorContainer">
          <Selector
            placeholder="Class"
            labelKey="name"
            valueKey="id"
            serviceType="CLASSES"
            onChange={(item) => setSelectedClass(item)}
            queryFilter={{ key: "year", dataValue: selectedYear }}
            disabled={!selectedYear}
            // defaultValue={inviteUser.role_id}
          />
        </div>
      </div>
      {selectedClass ? (
        <Form
          name="basic"
          initialValues={{ remember: true, subjectList }}
          onFinish={handleCreateSubject}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="formContainer">
            {subjectList.map((subject, key) => {
              return (
                <div className="form-row" key={key}>
                  <div className="input-element">
                    <Form.Item
                      name={"name" + subject.id}
                      rules={[
                        {
                          required: true,
                          message: "Please check your name",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={subject.name}
                    >
                      <Input
                        placeholder="Subject Name"
                        name={"name" + subject.id}
                        onChange={(e) =>
                          handleInput(key, "name", e.target.value)
                        }
                        defaultValue={subject.name}
                      />
                    </Form.Item>
                  </div>
                  <div className="input-element">
                    <Form.Item
                      name={"code" + subject.id}
                      rules={[
                        {
                          required: true,
                          message: "Please check your code",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={subject.code}
                    >
                      <Input
                        placeholder="Subject Code"
                        name={"code" + subject.id}
                        onChange={(e) =>
                          handleInput(key, "code", e.target.value)
                        }
                        defaultValue={subject.code}
                      />
                    </Form.Item>
                  </div>

                  <PlusCircleOutlined onClick={addRowInvite} />
                  <DeleteOutlined onClick={() => removeRowInvite(subject.id)} />
                </div>
              );
            })}
          </div>

          <Upload
            {...props}
            accept=".csv"
            beforeUpload={beforeUpload}
            customRequest={null}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          >
            <Typography
              variant="xs"
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Add Bulk Subjects
            </Typography>
          </Upload>

          <div className="action-buttons">
            <Form.Item style={{ width: 240 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                // loading={isLoading}
                disabled={!selectedClass}
              >
                Create
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : null}
    </div>
  );
}
