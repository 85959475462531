import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeToast } from "./actions/index.js";

import { notification } from "antd";
//rendering snackbar
const Toast = (props) => {
  const statusMapping = ["error", "success", "info", "warning"];
  const [api, contextHolder] = notification.useNotification();

  const { toasts, onClose } = props;

  const { removeToast } = props.actions;
  const placement = "bottomLeft";

  useEffect(() => {
    //eslint-disable-next-line
    // toasts.map((toast) => {
    //   const { id, message, status } = toast;
    //   const type = statusMapping[status];
    //   api[type]({
    //     message: capitalize(statusMapping[status]),
    //     key: id,
    //     description: message,
    //     placement,
    //     onClick: onClose,
    //     onClose: () => removeToast(id),
    //   });
    // });
    if (!toasts) return;
    const type = statusMapping[toasts.type];
    api[type]({
      message: toasts.message,
      key: 1,
      // description: toasts.message,
      placement,
      onClick: onClose,
      onClose: () => removeToast(1),
    });
    //eslint-disable-next-line
  }, [toasts]);

  return <>{contextHolder}</>;
};

const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ removeToast }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
