import * as React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";

const ProtectedRoute = (props) => {
  const isAuthenticated = localStorage.getItem("token");
  const hasAccess = props.accessKey
    ? props.userAccess.includes(props.accessKey)
    : true;
  console.log("has access", hasAccess);
  if (!isAuthenticated || !hasAccess) {
    // user is not authenticated
    return <Navigate to="/auth/login" />;
  }
  return props.children;
};

const mapStateToProps = (state) => ({
  userAccess: state.auth.userAccess,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
