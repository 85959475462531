import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_RESULT_LIST,
  API_ENDPOINT_SUBJECT_REMOVE,
} from "../../results.constants";

import "./ResultList.scss";
import UploadResult from "./components/UploadResult";
import Selector from "app/shared/Selector";
import moment from "moment";

const tableColumn = [
  {
    title: "Name",
    index: "user.user_profile.first_name",
    search: true,
  },
  {
    title: "Code",
    index: "user.sid",
    search: true,
  },

  {
    title: "Passing Marks",
    index: "passing_marks",
  },
  {
    title: "Marks Scored",
    index: "scored_marks",
    renderTag: true,
  },
  {
    title: "Result",
    index: "result",
    renderTag: true,
  },
];

function ResultList(props) {
  const [isLoading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [sujects, setSubjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedExam, setSelectedExam] = useState("");

  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedClass || !selectedExam) return;
    fetchResultList();
  }, [selectedClass, selectedExam]);
  const fetchResultList = async () => {
    setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_RESULT_LIST, { examId: selectedExam })
    );
    if (response.status) {
      setSubjects(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "delete":
        handleUserAccountStatus(event.rowData.id);

      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (subjectId) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_SUBJECT_REMOVE, {
        subjectId: subjectId,
        classId: selectedClass,
      })
    );
    if (response.status) {
      fetchResultList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className="ResultList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(data) => setSelectedClass(data)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Examination"
              labelKey="name"
              valueKey="id"
              serviceType="EXAMINATION"
              onChange={(data) => setSelectedExam(data)}
              queryFilter={{ key: "classId", dataValue: selectedClass }}
              disabled={!selectedClass}
            />
          </div>{" "}
        </div>
        <div className="rightSelectors">
          {props.userRole && props.userRole.role_name === "Admin" && (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => handleShowModal("INVITE")}>
                Upload Results
              </Button>
            </div>
          )}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={sujects}
          isLoading={isLoading}
          column={[...tableColumn]}
          handleAction={handleTableAction}
        />
      </div>

      <Modal
        title={"Upload Results"}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={1000}
        footer={null}
        userRole={props.userRole}
      >
        <UploadResult
          onCreate={() => {
            setShowModal(false);
            fetchResultList();
          }}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(ResultList);
