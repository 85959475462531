// import { PageNotFound } from "app/scene/Common";

import authRoute from "app/scenes/auth/auth.routes";
import onboardingRoutes from "app/scenes/onboarding/onboarding.routes";
import openResultRoutes from "app/scenes/OpenResult/openResult.routes";
import masterLayoutRoute from "app/shared/MasterLayout/master.layout.route";

const childRoutes = [
  authRoute,
  onboardingRoutes,
  masterLayoutRoute,
  openResultRoutes,
];

const routes = [
  ...childRoutes,
  //   {
  //     path: "*",
  //     exact: true,
  //     component: PageNotFound,
  //   },
];

export default routes;
