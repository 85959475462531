import Typography from "app/shared/Typography";
import QuickInsight from "./components/QuickInsight";
import SalesAndRent from "./components/SaleAndRent";
import AdmissionProgress from "./components/AdmissionProgress";
import "./dashboard.scss";
import { apiGet } from "app/services/apiService";
import {
  API_ENDPOINT_DASHBOARD_FETCH_ADMISSION_FLOW,
  API_ENDPOINT_DASHBOARD_FETCH_GIST,
} from "./dashboard.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "app/shared/Loader";
export default function Dashboard(props) {
  const [dashboardGist, setDashboardGist] = useState(null);
  const [dashboardFlow, setDashboardFlow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDashboardGist();
  }, []);
  const fetchDashboardGist = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_DASHBOARD_FETCH_GIST);
    if (response.status) {
      setDashboardGist(response.data);
      fetchDashboardAdmissionFlow();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const fetchDashboardAdmissionFlow = async () => {
    const response = await apiGet(API_ENDPOINT_DASHBOARD_FETCH_ADMISSION_FLOW);
    if (response.status) {
      setDashboardFlow(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return (
    <div className="Dashboard">
      <div className="greeting">
        <Typography variant="h5">Hi, Aman 👋</Typography>
        <Typography variant="caption" color="gray-dark">
          Welcome to Admin Panel
        </Typography>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="WidgetContainer">
          <div className="WidgetColumn" style={{ width: "100%" }}>
            <div className="widgetCard" style={{ width: "18%" }}>
              <QuickInsight
                type="STAFF"
                data={dashboardGist && dashboardGist?.staff}
              />
            </div>
            <div className="widgetCard" style={{ width: "18%" }}>
              <QuickInsight
                type="STUDENT"
                data={dashboardGist && dashboardGist?.students}
              />
            </div>
            <div className="widgetCard" style={{ width: "18%" }}>
              <QuickInsight
                type="EXAM"
                data={dashboardGist && dashboardGist?.exams}
              />
            </div>
            <div className="widgetCard" style={{ width: "18%" }}>
              <QuickInsight
                type="FAILED"
                data={dashboardGist && dashboardGist?.failed_student}
              />
            </div>
            <div className="widgetCard" style={{ width: "18%" }}>
              <QuickInsight
                type="PASSED"
                data={dashboardGist && dashboardGist?.passed_student}
              />
            </div>
            <div className="widgetCard" style={{ width: "100%" }}>
              <AdmissionProgress data={dashboardFlow} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
