// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Onboarding } from ".";
import OnboardingProfile from "./components/OnboardingProfile";
//eslint-disable-next-line
export default {
  path: "/onboard/",
  element: <Onboarding />,
  strict: true,
  children: [
    {
      path: "/onboard/profile",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <OnboardingProfile />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
