import { combineReducers } from "redux";

const { SAVE_USER_INFO, SAVE_USER_ACCESS_INFO } = require("./auth.action");

const userInfo = (state = null, action) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return action.payload;

    default:
      return state;
  }
};

const userAccess = (state = null, action) => {
  switch (action.type) {
    case SAVE_USER_ACCESS_INFO:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  userInfo: userInfo,
  userAccess: userAccess,
});
