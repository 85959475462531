import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_SUBJECT_LIST,
  API_ENDPOINT_USER_LIST,
  API_ENDPOINT_SUBJECT_REMOVE,
} from "../../subjects.constants";

import "./SubjectList.scss";
import CreateSubjects from "./components/CreateSubjects";
import Selector from "app/shared/Selector";
import moment from "moment";

const tableColumn = [
  {
    title: "Name",
    index: "name",
    search: true,
  },
  {
    title: "Code",
    index: "code",
    search: true,
  },

  {
    title: "Year",
    index: "class.year",
  },
  {
    title: "Class",
    index: "class.name",
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      adminOnly: true,
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "INVITED",
};

function SubjectList(props) {
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [sujects, setSubjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const dispatch = useDispatch();
  useEffect(() => {
    fetchSubjectList();
  }, [selectedClass]);
  const fetchSubjectList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_SUBJECT_LIST + "?class=" + selectedClass
    );
    if (response.status) {
      setSubjects(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "delete":
        handleUserAccountStatus(event.rowData.id);

      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (subjectId) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_SUBJECT_REMOVE, {
        subjectId: subjectId,
        classId: selectedClass,
      })
    );
    if (response.status) {
      fetchSubjectList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className="UsersList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(data) => setSelectedClass(data)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
            />
          </div>
        </div>
        <div className="rightSelectors">
          {props.userRole && props.userRole.role_name === "Admin" && (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => handleShowModal("INVITE")}>
                Create Subjects
              </Button>
            </div>
          )}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={sujects}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
        />
      </div>

      <Modal
        title={"Create Subjects"}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={1000}
        footer={null}
      >
        <CreateSubjects
          onCreate={() => {
            setShowModal(false);
            fetchSubjectList();
          }}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(SubjectList);
