import { Outlet } from "react-router";

import "./styles/results.scss";

export default function Results(props) {
  return (
    <div className="Results">
      <Outlet />
    </div>
  );
}
