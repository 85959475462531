import { REMOVE_TOAST_MESSAGE } from "../toast.constants";

const removeToast = (id) => {
  return {
    payload: id,
    type: REMOVE_TOAST_MESSAGE,
  };
};

export default removeToast;
