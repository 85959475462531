import {
  ArrowDownOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./userInvite.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { useEffect } from "react";
import {
  API_ENDPOINT_USER_CREATE_BULK_STUDENTS,
  API_ENDPOINT_USER_MOVE_BULK_STUDENTS,
} from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
import { colorPicker } from "app/utils/helpers/color.helper";
export default function MoveStudents(props) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const [selectedNextYear, setSelectedNextYear] = useState(
    moment().format("YYYY")
  );
  const [selectedNextClass, setSelectedNextClass] = useState("");
  const [selectedNextSection, setSelectedNextSection] = useState("");
  const dispatch = useDispatch();

  const handleMoveStudents = async () => {
    const payload = {
      currentSectionId: selectedSection,
      nextSectionId: selectedNextSection,
    };
    const response = await apiPost(
      API_ENDPOINT_USER_MOVE_BULK_STUDENTS,
      payload
    );

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <div className="MoveStudents">
      <div className="InviteSelectors">
        <div className="SelectorContainer">
          <Selector
            placeholder="Year"
            labelKey="value"
            valueKey="key"
            serviceType="STATIC"
            onChange={(data) => setSelectedYear(data)}
            defaultValue={selectedYear}
            staticData={[
              {
                key: "2019",
                value: 2019,
              },
              {
                key: "2020",
                value: 2020,
              },
              {
                key: "2021",
                value: 2021,
              },
              {
                key: "2022",
                value: 2022,
              },
              {
                key: "2023",
                value: 2023,
              },
              {
                key: "2024",
                value: 2024,
              },
            ]}
          />
        </div>

        <div className="SelectorContainer">
          <Selector
            placeholder="Class"
            labelKey="name"
            valueKey="id"
            serviceType="CLASSES"
            onChange={(item) => setSelectedClass(item)}
            queryFilter={{ key: "year", dataValue: selectedYear }}
            disabled={!selectedYear}
            // defaultValue={inviteUser.role_id}
          />
        </div>
        <div className="SelectorContainer">
          <Selector
            placeholder="Section"
            labelKey="name"
            valueKey="id"
            serviceType="SECTIONS"
            onChange={(item) => setSelectedSection(item)}
            // defaultValue={inviteUser.role_id}
            queryFilter={{ key: "classId", dataValue: selectedClass }}
            disabled={!selectedClass}
          />
        </div>
      </div>
      {selectedClass && selectedSection ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 24,
              paddingBottom: 24,
            }}
          >
            <ArrowDownOutlined
              style={{ fontSize: 34, color: colorPicker("gray_dark") }}
            />
          </div>
          <div className="InviteSelectors">
            <div className="SelectorContainer">
              <Selector
                placeholder="Year"
                labelKey="value"
                valueKey="key"
                serviceType="STATIC"
                onChange={(data) => setSelectedNextYear(data)}
                defaultValue={selectedNextYear}
                staticData={[
                  {
                    key: "2019",
                    value: 2019,
                  },
                  {
                    key: "2020",
                    value: 2020,
                  },
                  {
                    key: "2021",
                    value: 2021,
                  },
                  {
                    key: "2022",
                    value: 2022,
                  },
                  {
                    key: "2023",
                    value: 2023,
                  },
                  {
                    key: "2024",
                    value: 2024,
                  },
                ]}
              />
            </div>

            <div className="SelectorContainer">
              <Selector
                placeholder="Class"
                labelKey="name"
                valueKey="id"
                serviceType="CLASSES"
                onChange={(item) => setSelectedNextClass(item)}
                queryFilter={{ key: "year", dataValue: selectedNextYear }}
                disabled={!selectedNextYear}
                // defaultValue={inviteUser.role_id}
              />
            </div>
            <div className="SelectorContainer">
              <Selector
                placeholder="Section"
                labelKey="name"
                valueKey="id"
                serviceType="SECTIONS"
                onChange={(item) => setSelectedNextSection(item)}
                // defaultValue={inviteUser.role_id}
                queryFilter={{ key: "classId", dataValue: selectedNextClass }}
                disabled={!selectedNextClass}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="action-buttons">
        <Button
          type="primary"
          size="large"
          style={{ width: "100%" }}
          onClick={handleMoveStudents}
          disabled={
            !selectedClass ||
            !selectedSection ||
            !selectedNextClass ||
            !selectedNextSection
          }
        >
          Move Students
        </Button>
      </div>
    </div>
  );
}
