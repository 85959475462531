import Typography from "app/shared/Typography";
import StaffIcon from "assets/images/staff.webp";
import ExamIcon from "assets/images/exam.png";
import StudentIcon from "assets/images/student.png";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./QuickInsight.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
const imageType = {
  STAFF: StaffIcon,
  STUDENT: StudentIcon,
  FAILED: StudentIcon,
  EXAM: ExamIcon,
  PASSED: StudentIcon,
};

const titleMap = {
  STAFF: "Staff",
  STUDENT: "Students",
  EXAM: "Exam Conducted",
  FAILED: "Failed Students",
  PASSED: "Passed Students",
};
export default function QuickInsight(props) {
  return (
    <div
      className="QuickInsight"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <img src={imageType[props.type]} alt="Icon" style={{ width: 62 }} />
      </div>
      <div className="Stats">
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          {props.data}
        </Typography>
        <div className="DetailView">
          <Typography variant="caption" color="gray-dark">
            Total {titleMap[props.type]}
          </Typography>
          <ArrowRightAltIcon style={{ color: colorPicker("gray_dark") }} />
        </div>
      </div>
    </div>
  );
}
