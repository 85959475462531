import { Outlet } from "react-router";
import AuthIllustration from "assets/images/illustrations/authIllustration.png";
import AppLogo from "assets/images/appLogo.png";
import "./styles/auth.scss";
import BackgroundIllustration from "assets/images/login_back.svg"

export default function Auth(props) {
  return (
    <div className="auth">
      <div className="container">
        <div className="left-container">
          <div className="forms">
            <div className="appLogo">
              <img src={AppLogo} alt="appLogo" />
            </div>
            <Outlet />
          </div>
        </div>
        <div className="right-container">
          <img src={BackgroundIllustration}/>
          {/* <img src={AuthIllustration} alt="illustrationAuth" /> */}
        </div>
      </div>
    </div>
  );
}
