import { ADD_TOAST_MESSAGE, REMOVE_TOAST_MESSAGE } from "../toast.constants";
//eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case ADD_TOAST_MESSAGE:
      return action.payload;

    case REMOVE_TOAST_MESSAGE:
      return null;

    default:
      return state;
  }
}
