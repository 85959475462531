import Typography from "app/shared/Typography";
import { ResponsivePie } from "@nivo/pie";
import { colorPicker } from "app/utils/helpers/color.helper";
const data = [
  {
    id: "scala",
    label: "Sold",
    value: 424,
    color: colorPicker("green"),
  },
  {
    id: "haskell",
    label: "Rent",
    value: 573,
    color: colorPicker("red"),
  },
];
export default function SalesAndRent(props) {
  return (
    <div
      className="SalesAndRent"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography>Property Sales and Rent</Typography>
      </div>
      <div style={{ height: "100%" }}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={["#27ae60", "#e74c3c"]}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 14,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}
