export const ACCOUNT_STATUS_MAP = {
  ACTIVE: "Approved",
  PENDING: "Pending",
  DELETED: "Deleted",
  REJECTED: "Rejected",
  DISABLED: "Disabled",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DELETED: "DELETED",
  REJECTED: "REJECTED",
  DISABLED: "DISABLED",
};

export const ACCOUNT_STATUS_MAP_COLOR = {
  ACTIVE: "green",
  PENDING: "yellow",
  DELETED: "red",
  REJECTED: "red",
  DISABLED: "gray",
  PASS: "green",
  FAILED: "red",
};
