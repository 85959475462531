import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { API_ENDPOINT_CLASS_LIST } from "../../classes.constants";

import "./ClassList.scss";
import Selector from "app/shared/Selector";
import moment from "moment";
import CreateClass from "./components/CreateClass";
import EditClassSection from "./components/EditClassSection";

const tableColumn = [
  {
    title: "Name",
    index: "name",
    search: true,
  },

  {
    title: "Sections",
    index: "class_sections",
    multipleTag: true,
    tagIndex: "name",
  },

  // {
  //   title: "Students",
  //   index: "class_section.class.year",
  // },
];

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "INVITED",
};

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "edit_info",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
      adminOnly: true,
    },
  ],
};

function ClassList(props) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [classes, setClasses] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchClassList();
  }, [selectedYear]);
  const fetchClassList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_CLASS_LIST + "?year=" + selectedYear
    );
    if (response.status) {
      setClasses(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "edit_info":
        handleShowDrawer("EDIT");
        setSelectedItem(event.rowData);
        return;

      default:
        return null;
    }
  };

  return (
    <div className="ClassList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 0 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
        </div>
        <div className="rightSelectors">
          {props.userRole && props.userRole.role_name === "Admin" && (
            <>
              <div className="WidgetContainer" style={{ minWidth: 0 }}>
                <Button onClick={() => handleShowDrawer("CLASS")}>
                  Create Class
                </Button>
              </div>
              <div className="WidgetContainer" style={{ minWidth: 0 }}>
                <Button onClick={() => handleShowDrawer("SECTION")}>
                  Create Section
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={classes}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          userRole={props.userRole}
        />
      </div>
      <Drawer
        title={
          showDrawerFor === "CLASS"
            ? "Create Class"
            : showDrawerFor === "EDIT"
            ? "Edit Class Info"
            : "Create Section"
        }
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={400}
      >
        {showDrawerFor === "EDIT" ? (
          <EditClassSection
            classInfo={selectedItem}
            onCreate={() => {
              setShowDrawer(false);
              fetchClassList();
            }}
          />
        ) : (
          <CreateClass
            type={showDrawerFor === "CLASS" ? "CLASS" : "SECTION"}
            onCreate={() => {
              setShowDrawer(false);
              fetchClassList();
            }}
          />
        )}
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(ClassList);
