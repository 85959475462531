import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  DatePicker,
  Drawer,
  Modal,
  Popconfirm,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import dayjs from "dayjs";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_USER_LIST,
  API_ENDPOINT_USER_MARK_ATTENDANCE,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
  API_ENDPOINT_USER_VIEW_ATTENDANCE,
} from "../../attendance.constants";

import "./userList.scss";
import InviteStudents from "./components/InviteStudents";
import UserDetails from "app/shared/UserDetails";
import MoveStudents from "./components/MoveStudents";
import Selector from "app/shared/Selector";
import moment from "moment";
import EditStudent from "./components/EditStudent";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useLocation, useNavigate } from "react-router";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "INVITED",
};

function StudentList(props) {
  const [properties, setProperties] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [isLoading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [markedAttendence, setMarkAttendence] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY"));
  const navigate = useNavigate();

  const location = useLocation();

  const { classId, sectionId, subjectId } = location.state || {};

  const handleMarkAttendence = (studentId) => {
    if (markedAttendence.includes(studentId)) {
      setMarkAttendence(markedAttendence.filter((item) => item !== studentId));
    } else {
      setMarkAttendence([...markedAttendence, studentId]);
    }
  };

  useEffect(() => {
    if (classId || sectionId || subjectId) {
      setSelectedClass(classId);
      setSelectedSection(sectionId);
      setSelectedSubject(subjectId);
    }
  }, [location.state]);

  const tableColumn = [
    {
      title: "SID",
      index: "sid",
      search: true,
    },
    {
      title: "Name",
      index: "user_profile.first_name",
      search: true,
    },

    {
      title: "Attendance",
      index: "updatedAt",
      handleAction: (propsData) => propsData.handleAction(propsData),
      child: (itemlist) => {
        console.log("item data", itemlist);
        if (itemlist?.user_attendances.length === 0) return 0;
        let present_total = (itemlist?.user_attendances || []).filter(
          (item) => item.is_present === "YES"
        );
        return (
          <Tag>
            {(present_total.length / itemlist?.user_attendances.length) * 100}
          </Tag>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedSection) return;
    fetchUsersList();
  }, [selectedClass, selectedSection]);
  const fetchUsersList = async () => {
    setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_USER_VIEW_ATTENDANCE, {
        classId: selectedClass,
        sectionId: selectedSection,
        subjectId: selectedSubject,
      })
    );
    if (response.status) {
      setUsers(response.data);
      let responseList = [];
      response.data.forEach((student) => {
        responseList.push(student.id);
      });
      setMarkAttendence(responseList);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const markStudentAttendance = async () => {
    setLoading(true);
    let paylaod = {
      date: selectedDate,
      attendance: markedAttendence,
    };
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_MARK_ATTENDANCE, {
        classId: selectedClass,
        sectionId: selectedSection,
        subjectId: selectedSubject,
      }),
      paylaod
    );
    if (response.status) {
      setUsers(response.data);
      let responseList = [];
      response.data.forEach((student) => {
        responseList.push(student.id);
      });
      setMarkAttendence(responseList);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "view_detail":
        handleShowDrawer("DETAIL");
        setSelectedUser(event.rowData.id);
        return;
      case "edit_info":
        handleShowDrawer("EDIT");
        setSelectedUser(event.rowData);
        return;
      case "approve":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.ACTIVE);
        return;
      case "delete":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.DELETED);
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: userId,
        statusId: status,
      })
    );
    if (response.status) {
      fetchUsersList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleShowModal = (type) => {
    setShowModalFor(type);
    setShowModal(true);
  };

  return (
    <div className="UsersList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(data) => setSelectedClass(data)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
              defaultValue={selectedClass}
            />
          </div>

          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Section"
              labelKey="name"
              valueKey="id"
              serviceType="SECTIONS"
              onChange={(data) => setSelectedSection(data)}
              queryFilter={{ key: "classId", dataValue: selectedClass }}
              defaultValue={selectedSection}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Subject"
              labelKey="name"
              valueKey="id"
              serviceType="SUBJECTS"
              onChange={(data) => setSelectedSubject(data)}
              queryFilter={{ key: "classId", dataValue: selectedClass }}
              disabled={!selectedClass}
              defaultValue={selectedSubject}
            />
          </div>
        </div>
        <div>
          <div className="WidgetContainer" style={{ width: 200 }}>
            <Button
              onClick={() =>
                navigate("/attendance/mark", {
                  state: {
                    previousClass: selectedClass,
                    previousSectoin: selectedSection,
                    previousSubject: selectedSubject,
                  },
                })
              }
            >
              Mark Attendance
            </Button>
          </div>
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={users}
          isLoading={isLoading}
          column={[...tableColumn]}
          handleAction={handleTableAction}
          userRole={props.userRole}
        />
      </div>
      {/* {selectedSection && selectedSubject ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }}
        >
          <div className="WidgetContainer" style={{ width: 200 }}>
            <Button onClick={markStudentAttendance}>Mark Attendance</Button>
          </div>
        </div>
      ) : null} */}

      <Drawer
        title={showDrawerFor === "EDIT" ? "Edit Student" : "Student Details"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={showDrawerFor === "EDIT" ? 400 : 600}
      >
        {showDrawerFor === "DETAIL" ? (
          <UserDetails selectedUser={selectedUser} />
        ) : (
          <EditStudent
            onCreate={() => {
              setShowDrawer(false);
              fetchUsersList();
            }}
            userInfo={selectedUser}
          />
        )}
      </Drawer>

      <Modal
        title={showModalFor === "INVITE" ? "Create Students" : "Move Students"}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={1000}
        footer={null}
      >
        {showModalFor === "INVITE" ? (
          <InviteStudents
            onCreate={() => {
              setShowModal(false);
              fetchUsersList();
            }}
          />
        ) : (
          <MoveStudents
            onCreate={() => {
              setShowModal(false);
              fetchUsersList();
            }}
          />
        )}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(StudentList);
