import { Spin } from "antd";
import Button from "app/shared/Button";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import "../../styles/auth.scss";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_AUTH_VERIFY_EMAIL } from "../../auth.constants";
import { connect, useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";

function Verify(props) {
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setLoading] = useState(true);
  let { verify_token } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    verifyToken();
    //eslint-disable-next-line
  }, []);
  const verifyToken = async () => {
    setLoading(true);

    const response = await apiGet(
      API_ENDPOINT_AUTH_VERIFY_EMAIL + verify_token
    );
    if (response.status) {
      localStorage.setItem("token", response.data.accessToken);
      navigate("/auth/login");
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      if (response.code === 1008) navigate("/auth/login");
      setIsFailed(true);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return isLoading && !isFailed ? (
    <>
      <Typography style={{ textAlign: "center" }} variant="h5">
        Verifying your email address
      </Typography>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 54,
              marginTop: 24,
            }}
            spin
          />
        }
      />
    </>
  ) : (
    <div>
      <Typography style={{ textAlign: "center" }} variant="h4">
        Looks like you have provided wrong verification code!{" "}
      </Typography>
      {props.userInfo ? (
        <Button size="large" style={{ width: "100%" }} type="link" danger>
          Send again
        </Button>
      ) : (
        <Button
          size="large"
          style={{ width: "100%" }}
          type="link"
          danger
          onClick={() => navigate("/auth/login")}
        >
          Try login to get verification code again
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(Verify);
