import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image, Popconfirm, Switch, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_MAP,
  ACCOUNT_STATUS_MAP_COLOR,
} from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./UserDetails.scss";
import { API_ENDPOINT_USER_INFO } from "app/scenes/users/users.constants";
import { API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS } from "app/scenes/students/students.constants";

export default function UserDetails(props) {
  const [userInfo, setUserInfo] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchBrokerInfo();
  }, [props.selectedUser]);
  const fetchBrokerInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_USER_INFO + props.selectedUser);
    if (response.status) {
      setUserInfo(response.data);
    } else {
      setUserInfo(null);

      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleUserAccountStatus = async (status) => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: props.selectedUser,
        statusId: status,
      })
    );
    if (response.status) {
      fetchBrokerInfo();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };
  return isLoading ? (
    <Loader />
  ) : userInfo === null ? (
    <div>
      <Typography>No Profile</Typography>
    </div>
  ) : (
    <div className="BrokerDetails">
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Status
          </Typography>
          <Tag color={ACCOUNT_STATUS_MAP_COLOR[userInfo && userInfo?.status]}>
            {userInfo && userInfo?.status}
          </Tag>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Account (Enable/Disable)
          </Typography>
          <Popconfirm
            title={`Are you sure want to ${
              userInfo && userInfo?.status === "ACTIVE" ? "disable" : "enable"
            } this account`}
            // description="Are you sure to delete this task?"
            onConfirm={() =>
              handleUserAccountStatus(
                userInfo && userInfo?.status === "ACTIVE"
                  ? ACCOUNT_STATUS.DISABLED
                  : ACCOUNT_STATUS.ACTIVE
              )
            }
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              size="small"
              checked={userInfo && userInfo?.status === "ACTIVE"}
              disabled={userInfo && userInfo?.status === "PENDING"}
            />
          </Popconfirm>
        </div>
      </div>
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Name
          </Typography>
          <Typography color="gray-dark">
            {userInfo && userInfo.user_profile?.full_name}{" "}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Mobile
          </Typography>
          <Typography color="gray-dark">
            {userInfo && userInfo.user_profile?.mobile}
          </Typography>
        </div>
      </div>
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Email
          </Typography>
          <Typography color="gray-dark">
            {userInfo && userInfo.email}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            City
          </Typography>
          <Typography color="gray-dark">
            {(userInfo && userInfo.user_profile?.city) || "NA"}
          </Typography>
        </div>
      </div>

      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Created on
          </Typography>
          <Typography color="gray-dark">
            {moment(userInfo && userInfo.createdAt).format("DD MMM YY")}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Gender
          </Typography>
          <Typography color="gray-dark">
            {(userInfo && userInfo.user_profile?.gender) || "NA"}
          </Typography>
        </div>
      </div>
    </div>
  );
}
