import { Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "../../styles/onboarding.scss";

import { connect, useDispatch } from "react-redux";
import OTPBox from "app/shared/OTPBox";

function OnbaordingProfile(props) {
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sceneTab, setSceneTab] = useState(0);
  const dispatch = useDispatch();

  return sceneTab === 0 ? (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      // onFinish={handleRegister}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Typography style={{ textAlign: "center" }} variant="h5">
        Update Profile
      </Typography>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="firstname"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
        >
          <Input
            placeholder="First Name"
            type="email"
            label="First Name"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="input-element" style={{ marginBottom: 0 }}>
        <Form.Item
          name="lastname"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
        >
          <Input
            placeholder="Last Name"
            type="email"
            label="Last Name"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your mobile!",
              fontSize: 12,
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Input
            placeholder="Mobile"
            label="Mobile"
            type="phone"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            // htmlType="submit"
            onClick={() => setSceneTab(1)}
          >
            Update & Verify Mobile
          </Button>
        </Form.Item>
      </div>
    </Form>
  ) : (
    <>
      <Typography style={{ textAlign: "center" }} variant="h5">
        Verify Mobile
      </Typography>
      <Typography
        color="gray-dark"
        variant="body"
        style={{ marginBottom: 72, textAlign: "center" }}
      >
        We have send you an OTP to your provided mobile number
      </Typography>
      <div className="input-elements" style={{ marginBottom: 14 }}>
        <OTPBox />
      </div>
      <Typography variant="caption" style={{ textAlign: "center" }}>
        Didn't receive OTP?{" "}
      </Typography>
      <Typography variant="caption" style={{ textAlign: "center" }}>
        Resend in 33 sec
      </Typography>
      <div className="action-buttons">
        <Button
          type="primary"
          size="large"
          style={{ width: "100%" }}
          onClick={() => setSceneTab(0)}
        >
          Verify
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(OnbaordingProfile);
