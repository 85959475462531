import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Tag, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./userInvite.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON } from "app/shared/Selector/selector.constants";
import { useEffect } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  API_ENDPOINT_USER_CREATE_BULK,
  API_ENDPOINT_USER_CREATE_BULK_STUDENTS,
} from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
export default function InviteStudents(props) {
  const [roleMap, setRoleMap] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [inviteList, setInviteList] = useState([
    {
      id: 1,
      email: "",
      first_name: "",
      last_name: "",
      sid: "",
    },
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchRoleMap();
  }, []);
  const fetchRoleMap = async () => {
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON + "?service=USER_ROLES"
    );
    let newObjMap = {};
    if (response.status) {
      response.data.forEach((role) => {
        newObjMap[role.role_name] = role.id;
      });
      setRoleMap(newObjMap);
    }
  };

  const addRowInvite = () => {
    const currentId = inviteList[inviteList.length - 1].id;
    setInviteList([
      ...inviteList,
      { email: "", role_id: "", sid: "", id: currentId + 1 },
    ]);
  };

  const removeRowInvite = (itemId) => {
    setInviteList(inviteList.filter((item) => item.id !== itemId));
  };

  const handleInviteUsers = async () => {
    const users = [];
    inviteList.forEach((element) => {
      users.push({
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email,
        classSectionId: selectedSection,
        sid: element.sid,
      });
    });

    const response = await apiPost(API_ENDPOINT_USER_CREATE_BULK_STUDENTS, {
      students: users,
    });

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const handleInput = (rowId, key, data) => {
    let prevData = JSON.parse(JSON.stringify(inviteList));
    prevData[rowId][key] = data;
    setInviteList(prevData);
  };

  const beforeUpload = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        addParsedEntry(results.data);
      },
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const addParsedEntry = (entries) => {
    let newEntries = [];
    let newId = inviteList.length + 1;
    entries.forEach((element) => {
      if (validateEmail(element[0])) {
        newEntries.push({
          email: element[0],
          first_name: element[1],
          last_name: element[2],
          sid: element[3],
          id: newId,
        });
        newId = newId + 1;
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: "Invalid CSV file", type: 0 },
        });
        newEntries = [];
        return;
      }
    });
    setInviteList([...inviteList, ...newEntries]);
  };

  const downloadSampleCSV = () => {
    // Define the CSV content with headers and some sample data
    const csvContent =
      "email,first_name,last_name,sid\n" + // CSV header
      "student1@example.com,John,Doe,SID001\n" + // Sample data
      "student2@example.com,Jane,Smith,SID002\n" +
      "student3@example.com,Mark,Johnson,SID003\n" +
      "student4@example.com,Lisa,Brown,SID004\n";

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);

    // Set the file name for the download
    link.setAttribute("download", "sample_invite.csv");

    // Append the link to the body (for Firefox support)
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up by removing the link after the download
    document.body.removeChild(link);
  };

  // Call the function to download the CSV

  return (
    <div className="InviteStudents">
      <div
        className="InviteSelectors"
        style={{
          borderBottom:
            selectedClass && selectedSection
              ? "1px solid rgb(235, 230, 230)"
              : "none",
        }}
      >
        <div className="SelectorContainer">
          <Selector
            placeholder="Year"
            labelKey="value"
            valueKey="key"
            serviceType="STATIC"
            onChange={(data) => setSelectedYear(data)}
            defaultValue={selectedYear}
            staticData={[
              {
                key: "2019",
                value: 2019,
              },
              {
                key: "2020",
                value: 2020,
              },
              {
                key: "2021",
                value: 2021,
              },
              {
                key: "2022",
                value: 2022,
              },
              {
                key: "2023",
                value: 2023,
              },
              {
                key: "2024",
                value: 2024,
              },
            ]}
          />
        </div>

        <div className="SelectorContainer">
          <Selector
            placeholder="Class"
            labelKey="name"
            valueKey="id"
            serviceType="CLASSES"
            onChange={(item) => setSelectedClass(item)}
            queryFilter={{ key: "year", dataValue: selectedYear }}
            disabled={!selectedYear}
            // defaultValue={inviteUser.role_id}
          />
        </div>
        <div className="SelectorContainer">
          <Selector
            placeholder="Section"
            labelKey="name"
            valueKey="id"
            serviceType="SECTIONS"
            onChange={(item) => setSelectedSection(item)}
            // defaultValue={inviteUser.role_id}
            queryFilter={{ key: "classId", dataValue: selectedClass }}
            disabled={!selectedClass}
          />
        </div>
      </div>
      {selectedClass && selectedSection ? (
        <Form
          name="basic"
          initialValues={{ remember: true, inviteList }}
          onFinish={handleInviteUsers}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="formContainer">
            {inviteList.map((inviteUser, key) => {
              return (
                <div className="form-row" key={key}>
                  <div className="input-element">
                    <Form.Item
                      name={"email" + inviteUser.id}
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please check your email",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={inviteUser.email}
                    >
                      <Input
                        placeholder="Email"
                        name={"email" + inviteUser.id}
                        onChange={(e) =>
                          handleInput(key, "email", e.target.value)
                        }
                        defaultValue={inviteUser.email}
                      />
                    </Form.Item>
                  </div>
                  <div className="input-element">
                    <Form.Item
                      name={"first_name" + inviteUser.id}
                      rules={[
                        {
                          required: true,
                          message: "Please check your first name",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={inviteUser.first_name}
                    >
                      <Input
                        placeholder="First Name"
                        name={"first_name" + inviteUser.id}
                        onChange={(e) =>
                          handleInput(key, "first_name", e.target.value)
                        }
                        defaultValue={inviteUser.first_name}
                      />
                    </Form.Item>
                  </div>
                  <div className="input-element">
                    <Form.Item
                      name={"last_name" + inviteUser.id}
                      rules={[
                        {
                          required: true,
                          message: "Please check your last name",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={inviteUser.last_name}
                    >
                      <Input
                        placeholder="Last Name"
                        name={"last_name" + inviteUser.id}
                        onChange={(e) =>
                          handleInput(key, "last_name", e.target.value)
                        }
                        defaultValue={inviteUser.last_name}
                      />
                    </Form.Item>
                  </div>
                  <div className="input-element">
                    <Form.Item
                      name={"sid" + inviteUser.id}
                      rules={[
                        {
                          required: true,
                          message: "Please check your SID",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={inviteUser.sid}
                    >
                      <Input
                        placeholder="SID/USN"
                        name={"sid" + inviteUser.id}
                        onChange={(e) =>
                          handleInput(key, "sid", e.target.value)
                        }
                        defaultValue={inviteUser.sid}
                      />
                    </Form.Item>
                  </div>

                  <PlusCircleOutlined onClick={addRowInvite} />
                  <DeleteOutlined
                    onClick={() => removeRowInvite(inviteUser.id)}
                  />
                </div>
              );
            })}
          </div>

          <Upload
            {...props}
            accept=".csv"
            beforeUpload={beforeUpload}
            customRequest={null}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          >
            <Typography
              variant="xs"
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Add Bulk User
            </Typography>
          </Upload>

          <Tag
            icon={<DownloadOutlined />}
            onClick={downloadSampleCSV}
            style={{ cursor: "pointer" }}
          >
            Download Sample CSV For Bulk Invite
          </Tag>

          <div className="action-buttons">
            <Form.Item style={{ width: 240 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                // loading={isLoading}
                disabled={!selectedClass || !selectedSection}
              >
                Send Invite
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : null}
    </div>
  );
}
