import { Form, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import { useEffect } from "react";
import {
  API_ENDPOINT_USER_CREATE_BULK_STUDENTS,
  API_ENDPOINT_USER_UPDATE_INFO,
} from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
import {
  API_ENDPOINT_CLASS_CREATE,
  API_ENDPOINT_CLASS_CREATE_SECTION,
  API_ENDPOINT_CLASS_UPDATE_INFO,
} from "app/scenes/classes/classes.constants";
import { parseURL } from "app/utils/helpers/common.helper";
export default function EditClassSection(props) {
  const [className, setClassName] = useState("");
  //   const [sid, setSID] = useState("");
  console.log("class info:", props.classInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.classInfo) {
      setClassName(props.classInfo?.name);
    }
  }, [props.classInfo]);

  const updateClassSection = async () => {
    const payload = {
      name: className,
    };

    const response = await apiPut(
      API_ENDPOINT_CLASS_UPDATE_INFO + props.classInfo?.id,
      payload
    );

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <div className="CreateClass">
      <div className="InviteSelectors"></div>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={updateClassSection}
        // onFinishFailed={onFinishFailed}
        fields={[
          {
            name: ["class_name"],
            value: className,
          },
        ]}
        autoComplete="off"
      >
        <div className="formContainer">
          <div className="form-row" style={{ flexDirection: "column" }}>
            {/* <div className="input-element">
              <Form.Item
                name={"sid"}
                rules={[
                  {
                    required: true,
                    message: "Please check your name",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder="SID"
                  name={"sid"}
                  onChange={(e) => setSId(e.target.value)}
                />
              </Form.Item>
            </div> */}
            <div className="input-element">
              <Form.Item
                name={"class_name"}
                rules={[
                  {
                    required: true,
                    message: "Please check your name",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder="Class Name"
                  label="Class Name"
                  name={"class_name"}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </Form.Item>
            </div>

            {/* <div className="input-element" style={{ marginTop: 24 }}>
              <Typography>Class Sections</Typography>
            </div>
            <div className="input-element" style={{ marginBottom: 24 }}>
              <Form.Item
                name={"last_name"}
                rules={[
                  {
                    required: true,
                    message: "Please check your name",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder="Last Name"
                  name={"last_name"}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </Form.Item>
            </div> */}
          </div>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              // loading={isLoading}
              // disabled={!selecte}
            >
              Update
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
