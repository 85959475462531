import { LoadingOutlined } from "@ant-design/icons";
import { Form, Spin } from "antd";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  API_ENDPOINT_AUTH_REGISTER,
  API_ENDPOINT_AUTH_VERIFY_INVITE,
} from "../../auth.constants";

import "../../styles/auth.scss";

export default function Register(props) {
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  useEffect(() => {
    verifyInvite();
    //eslint-disable-next-line
  }, []);
  const verifyInvite = async () => {
    setVerifying(true);
    const inviteLink = params.get("invite");

    const response = await apiGet(
      API_ENDPOINT_AUTH_VERIFY_INVITE + "?invite=" + inviteLink
    );
    if (response.status) {
      setEmail(response.data.email);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      navigate("/auth/login");
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setVerifying(false);
  };
  const handleRegister = async () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
      password_confirm: confirmPassword,
    };

    if (password === confirmPassword) {
      const response = await apiPost(API_ENDPOINT_AUTH_REGISTER, payload);
      if (response.status) {
        localStorage.setItem("token", response.data.accessToken);
        navigate("/");
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Passwords do not match", type: 0 },
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return verifying ? (
    <>
      <Typography style={{ textAlign: "center" }} variant="h5">
        Verifying your invitation
      </Typography>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 54,
              marginTop: 24,
            }}
            spin
          />
        }
      />
    </>
  ) : (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true, email }}
        onFinish={handleRegister}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="Email"
              type="email"
              label="Email"
              disabled
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="input-element" style={{ marginBottom: 0 }}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your confirm password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Password"
              type="password"
              label="Password"
              onChange={(e) => setPasword(e.target.value)}
              name="password"
            />
          </Form.Item>
        </div>
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Confirm Password"
              type="password"
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              name="confirm_password"
            />
          </Form.Item>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Sign up
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
