import { Outlet } from "react-router";

import "./styles/users.scss";

export default function Users(props) {
  return (
    <div className="Users">
      <Outlet />
    </div>
  );
}
