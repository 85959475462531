export default {
  "/dashboard": "Dashboard",
  "/users/invite": "Invite User",
  "/users/list": "Users",
  "/students/list": "Students",
  "/class/list": "Class",
  "/subjects/list": "Subjects",
  "/exams/list": "Exams",
  "/results/list": "Results",
};
