// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Students } from ".";
import StudentList from "./components/StudentList";
//eslint-disable-next-line
export default {
  path: "/students/",
  element: <Students />,
  strict: true,
  children: [
    {
      path: "/students/list",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <StudentList />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
