// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Subjects } from ".";
import SubjectList from "./components/SubjectList";
//eslint-disable-next-line
export default {
  path: "/subjects/",
  element: <Subjects />,
  strict: true,
  children: [
    {
      path: "/subjects/list",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <SubjectList />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
