import { Button as AntButton } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";

export default function Button(props) {
  return (
    <AntButton
      type={props.type || "primary"}
      size={props.size || "large"}
      style={{
        borderRadius: 10,
        width: "100%",
        height: props.height || 50,
        backgroundColor:
          props.background?props.background:props.type === "link" ? "transparent" : colorPicker("primary"),
        boxShadow: "none",
      }}
      htmlType={props.htmlType}
      // {...props}
      onClick={props.onClick && props.onClick}
      danger={props.danger}
      disabled={props.disabled}
      loading={props.loading}
      icon={props.icon}
    >
      {props.children || "Button"}
    </AntButton>
  );
}
