import React, { useState } from "react";
import { ConfigProvider, Select } from "antd";
import { apiGet } from "app/services/apiService";
import { useEffect } from "react";
import {
  API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON,
  API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON_OPEN,
} from "./selector.constants";

const Selector = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({});
  useEffect(() => {
    console.log("current filter", currentFilter);
    console.log("next filter", props.queryFilter);
    if (JSON.stringify(props.queryFilter) !== currentFilter) {
      setCurrentFilter(JSON.stringify(props.queryFilter));

      if (props.serviceType === "STATIC") {
        setItemList(props.staticData);
      } else {
        fetchItemList();
      }
    }
  }, [props.queryFilter]);

  useEffect(() => {
    if (props.autoSelect) handleAutoSelect();
  }, [itemList]);

  const handleAutoSelect = () => {
    let findItem = itemList.find(
      (item) =>
        item[props.labelKey] === props.autoSelect ||
        item[props.valueKey] === props.autoSelect
    )?.[props.valueKey];
    setSelectedItems(findItem);
    props.onChange && props.onChange(findItem);
  };
  const fetchItemList = async () => {
    const ITEM_LIST_ENDPOINT = props.openAPI
      ? API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON_OPEN
      : API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON;
    const response = await apiGet(
      ITEM_LIST_ENDPOINT +
        "?service=" +
        props.serviceType +
        (props.queryFilter
          ? "&filterKey=" +
            props.queryFilter?.key +
            "&filterValue=" +
            props.queryFilter?.dataValue
          : "")
    );
    if (response.status) {
      setItemList(response.data);
    }
  };

  const handleChangeInput = (items) => {
    if (props.name) {
      let fullObject = itemList.find((x) => x[props.valueKey] === items);

      props.onChange &&
        props.onChange({
          target: { name: props.name, value: items, obj: fullObject },
        });
    } else {
      props.onChange && props.onChange(items);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            selectorBg: "white",
          },
        },
      }}
    >
      <Select
        mode={props.mode || "single"}
        placeholder={props.placeholder || "Select"}
        value={props.defaultValue || selectedItems}
        disabled={props.disabled}
        onChange={(items) => {
          handleChangeInput(items);
          setSelectedItems(items);
        }}
        defaultValue={props.defaultValue || selectedItems}
        style={{
          width: "100%",
          height: 36,
          borderRadius: 12,
        }}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {itemList.map((option) => (
          <Select.Option key={option[props.valueKey]}>
            {option[props.labelKey]}
          </Select.Option>
        ))}
      </Select>
    </ConfigProvider>
  );
};
export default Selector;
