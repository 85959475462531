import React from "react";
import {
  AppstoreOutlined,
  BookOutlined,
  DesktopOutlined,
  HomeOutlined,
  LineChartOutlined,
  LogoutOutlined,
  SettingOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { ConfigProvider, Layout, Menu, Tag, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./masterlayout.scss";
import AppLogo from "assets/images/appLogo.png";
import Typography from "../Typography";
import color from "theme/color";
import HeaderContent from "app/shared/Header";
import UserAvatar from "../UserAvatar";
import { connect } from "react-redux";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Dashboard", "/", <AppstoreOutlined />),

  getItem("Staffs", "/users/list", <UserOutlined />),
  getItem("Students", "/students/list", <UserOutlined />),
  getItem("Classes", "/class/list", <DesktopOutlined />),
  getItem("Exams", "/exams/list", <DesktopOutlined />),
  getItem("Attendance", "/attendance/view", <FileDoneOutlined />),
  getItem("Subjects", "/subjects/list", <BookOutlined />),
  getItem("Results", "/results/list", <LineChartOutlined />),
  // getItem("Settings", "sub7", <SettingOutlined />),
];

const { Header, Content, Footer, Sider } = Layout;
const MasterLayout = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/login");
  };
  const handleMenu = (menu) => {
    navigate(menu.key);
  };

  return (
    <Layout
      style={{ height: "100vh", backgroundColor: "#F0F2FD" }}
      className="masterLayout"
    >
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        style={{ position: "absolute", height: "100vh", zIndex: 1 }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        width={256}
        theme="light"
      >
        <div className="leftSideNav">
          <div className="LogoMenuContainer">
            <div className="appLogoContainer">
              <img src={AppLogo} alt="appLogo" className="profileImage" />
            </div>
            <div className="userInfoContainer">
              <div className="profileImage">
                <UserAvatar
                  avatar={
                    props.userInfo && props.userInfo?.user_profile?.avatar
                  }
                />
              </div>
              <div className="userInfo">
                <Typography variant="body" style={{ fontSize: 16 }}>
                  {props.userInfo && props.userInfo?.user_profile?.full_name}
                </Typography>
                <Typography variant="caption" color="gray_text">
                  <Tag color="blue" style={{ height: 22 }}>
                    {props.userInfo && props.userInfo?.user_role?.role_name}
                  </Tag>
                </Typography>
              </div>
            </div>
            <div className="menuContainer">
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      itemBorderRadius: 0,
                      inlineIndent: 0,
                      groupTitleColor: color.red,
                      colorBgTextActive: color.red,
                    },
                  },
                }}
              >
                <Menu
                  onClick={handleMenu}
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                  theme="light"
                  items={items}
                  style={{
                    color: color.gray_dark,
                    width: "100%",
                    margin: 0,
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
          <div className="logout" onClick={handleLogout}>
            <LogoutOutlined style={{ color: "#8792A4" }} />
            <Typography
              variant="button"
              style={{ marginLeft: 20, color: "#8792A4" }}
            >
              Logout
            </Typography>
          </div>
        </div>
      </Sider>
      <Layout
        className="MasterLayoutContent"
        style={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "#F0F2FD",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: "#F0F2FD",
            height: 88,
          }}
        >
          <HeaderContent {...props} />
        </Header>
        <Content
          style={{
            backgroundColor: "#F0F2FD",
            padding: 24,
          }}
        >
          <div
            style={{
              minHeight: 360,
              background: "#F0F2FD",
              height: "100%",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(MasterLayout);
