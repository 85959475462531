import "./App.css";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import Entry from "Entry";
import { Provider } from "react-redux";
import configureStore from "store";
import Toast from "app/shared/Toast/Toast";

function App() {
  // console.log("routeconfig", routeConfig);
  return (
    <Provider store={configureStore}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#2e3c53",
            colorSecondary: "white",
            fontFamily: "Futura",
          },
        }}
      >
        <Router>
          <Entry />
        </Router>
        <Toast />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
