import ProtectedRoute from "app/utils/ProtectedRoute";
import { Auth } from "./";
import Authenticate from "./components/authenticate";
import ChangePassword from "./components/ChangePassword";
import ForgotPassowrd from "./components/ForgotPassowrd";
import Login from "./components/login";
import Register from "./components/register";
import SignupSuccess from "./components/SignupSuccess";
import Verify from "./components/verify";
//eslint-disable-next-line
export default {
  path: "/auth/",
  element: <Auth />,
  strict: true,
  children: [
    {
      path: "/auth/login",
      element: <Login />,
      exact: true,
    },
    {
      path: "/auth/signup_success",
      element: <SignupSuccess />,
      exact: true,
    },
    {
      path: "/auth/forgot_password",
      element: <ForgotPassowrd />,
      exact: true,
    },
    {
      path: "/auth/change_password/:resetToken",
      element: <ChangePassword />,
      exact: true,
    },
    {
      path: "/auth/verify/:verify_token",
      element: <Verify />,
      exact: true,
    },
    {
      path: "/auth/register",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <Register />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
