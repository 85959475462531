import { Input as AntInput, Select, Space } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import Typography from "../Typography";
import DialCodes from "app/constants/DialCodes.json";
import { useState } from "react";
import { useEffect } from "react";
const options = DialCodes;
export default function Input(props) {
  const [selectedCode, setSelectedCode] = useState("+91");

  return (
    <div key={props.name}>
      {props.label ? (
        <Typography variant="body" weight={400} style={{ marginLeft: 4 }}>
          {props.label}
        </Typography>
      ) : null}
      {props.type === "phone" ? (
        <Space.Compact style={{ width: "100%" }}>
          <Select
            defaultValue="+91"
            options={props.options || options}
            style={{
              minWidth: 100,
              maxWidth: 120,
              color: "white",
              height: "2.8rem",
            }}
            // onChange={(text) => setSelectedCode(text)}
            size={"large"}
            name={props.name}
          />
          <AntInput
            style={{
              backgroundColor: colorPicker("gray_light"),
              borderRadius: 10,
              borderColor: "#fff",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            type={props.type || "string"}
            defaultValue={props.defaultValue}
            name={props.name}
            onChange={(e) => props.onChange && props.onChange(e.target.value)}
            value={props.value}
          />
        </Space.Compact>
      ) : (
        <AntInput
          type={props.type || "string"}
          placeholder={props.placeholder || ""}
          style={{
            backgroundColor: colorPicker(props.background || "gray_light"),
            borderRadius: 10,
            borderColor: props.borderColor || "#fff",
            height: props.height || 48,
          }}
          name={props.name}
          onChange={props.onChange && props.onChange}
          status={(props.error && props.error.includes(props.name)) || ""}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          value={props.value}
          size={props.size || "medium"}
          onBlur={props.onBlur && props.onBlur}
          maxLength={props.maxLength}
        />
      )}
    </div>
  );
}
