import { Area } from "@ant-design/plots";
import { ResponsiveLine } from "@nivo/line";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
const data = [
  {
    color: "hsl(123, 70%, 50%)",
    data: [
      {
        color: "hsl(40, 70%, 50%)",
        x: "Jul 2018",
        y: 12,
      },
      {
        color: "hsl(232, 70%, 50%)",
        x: "May 2019",
        y: 55,
      },
      {
        color: "hsl(353, 70%, 50%)",
        x: "Jun 2020",
        y: 23,
      },
      {
        color: "hsl(1, 70%, 50%)",
        x: "Jul 2021",
        y: 20,
      },
      {
        color: "hsl(334, 70%, 50%)",
        x: "Aug 2022",
        y: 22,
      },
      {
        color: "hsl(199, 70%, 50%)",
        x: "AO 2023",
        y: 55,
      },
      {
        color: "hsl(151, 70%, 50%)",
        x: "Sep 2024",
        y: 23,
      },
    ],
    id: "whisky",
  },
];

export default function AdmissionProgress(props) {
  const config = {
    data,
    xField: "Date",
    yField: "scales",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    yAxis: {
      tickCount: 5,
    },
  };

  const processData = (data) => {
    return data.map((element) =>
      Object.assign(element, { x: element.year, y: element.count })
    );
  };
  return (
    <div
      className="SalesOverview"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography>Student Admission</Typography>
      </div>
      <div style={{ height: "90%" }}>
        <ResponsiveLine
          data={[
            {
              color: "hsl(123, 70%, 50%)",
              data: processData(props.data),
              id: "students",
            },
          ]}
          margin={{ top: 40, right: 20, bottom: 30, left: 30 }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
          }}
          yFormat=" >-.2f"
          curve="monotoneX"
          axisTop={null}
          colors={[colorPicker("red")]}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          defs={[
            {
              colors: [
                {
                  color: "inherit",
                  offset: 0,
                },
                {
                  color: "inherit",
                  offset: 100,
                  opacity: 0,
                },
              ],
              id: "gradientA",
              type: "linearGradient",
            },
          ]}
          axisLeft={{
            tickValues: [0, 50, 100, 500],
            format: (tick) => `${tick}`,
          }}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          pointSize={10}
          pointBorderWidth={2}
          enableArea={true}
          enableCrosshair={false}
          useMesh={true}
          fill={[
            {
              id: "gradientA",
              match: "*",
            },
          ]}
          legends={[]}
        />
      </div>
    </div>
  );
}
