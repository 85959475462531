import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_EXAMS_LIST,
  API_ENDPOINT_EXAMS_UPDATE_STATUS,
} from "../../exams.constants";

import "./ExamList.scss";
import Selector from "app/shared/Selector";
import moment from "moment";
import CreateExam from "./components/CreateExam";

const tableColumn = [
  {
    title: "Name",
    index: "name",
    search: true,
  },

  {
    title: "Year",
    index: "year",
  },
  {
    title: "Marks Type",
    index: "marks_type",
    renderTag: true,
  },
  {
    title: "Status",
    index: "status",
    renderTag: true,
  },
];

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "INVITED",
};

function ExamList(props) {
  const [selectedClass, setSelectedClass] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [isLoading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [classes, setClasses] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedClass) return;
    fetchExamList();
  }, [selectedClass]);
  const fetchExamList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_EXAMS_LIST + "?class=" + selectedClass
    );
    if (response.status) {
      setClasses(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "approve":
        updateExamStatus(event.rowData.id, "COMPLETED");
        return;
      case "delete":
        updateExamStatus(event.rowData.id, "DELETED");
      default:
        return null;
    }
  };

  const actionColumnObj = {
    title: "Action",
    renderAction: true,
    handleAction: (propsData) => propsData.handleAction(propsData),
    actions: [
      {
        event: "approve",
        icon: <CheckOutlined style={{ fontSize: 12, color: "white" }} />,
        color: "#27ae60",
        popup: true,
        popupTitle: "Are you sure want to complete this exam?",
        showOn: (data) => data.status !== "COMPLETED",
        adminOnly: true,
      },

      {
        event: "delete",
        icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
        color: "#FF0000",
        popup: true,
        popupTitle: "Are you sure want to delete this exam?",
        adminOnly: true,
      },
    ],
  };

  const updateExamStatus = async (examId, statusId) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_EXAMS_UPDATE_STATUS, {
        examId: examId,
        statusId: statusId,
      })
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      fetchExamList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <div className="ExamList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(data) => setSelectedClass(data)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
            />
          </div>
        </div>
        <div className="rightSelectors">
          {props.userRole && props.userRole.role_name === "Admin" && (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => handleShowDrawer("EXAM")}>
                Create Exam
              </Button>
            </div>
          )}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={classes}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          userRole={props.userRole}
        />
      </div>
      <Drawer
        title={showDrawerFor === "CLASS" ? "Create Class" : "Create Section"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={400}
      >
        <CreateExam
          type={showDrawerFor === "CLASS" ? "CLASS" : "SECTION"}
          onCreate={() => {
            setShowDrawer(false);
            fetchExamList();
          }}
        />
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(ExamList);
