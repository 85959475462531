import { Outlet } from "react-router";

import "./styles/users.scss";

export default function Exams(props) {
  return (
    <div className="Exams">
      <Outlet />
    </div>
  );
}
