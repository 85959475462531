import { Form, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./CreateExam.scss";
import { apiGet, apiPost } from "app/services/apiService";
import { useEffect } from "react";
import { API_ENDPOINT_USER_CREATE_BULK_STUDENTS } from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import moment from "moment";
import {
  API_ENDPOINT_CLASS_CREATE,
  API_ENDPOINT_CLASS_CREATE_SECTION,
} from "app/scenes/classes/classes.constants";
import { parseURL } from "app/utils/helpers/common.helper";
import { API_ENDPOINT_EXAMS_CREATE } from "app/scenes/exams/exams.constants";
export default function CreateExam(props) {
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedClass, setSelectedClass] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const createExam = async () => {
    const payload = {
      name: name,
      year: selectedYear,
      classId: selectedClass,
    };
    const response = await apiPost(API_ENDPOINT_EXAMS_CREATE, payload);

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <div className="CreateExam">
      <div className="InviteSelectors">
        <div className="SelectorContainer">
          <Selector
            placeholder="Year"
            labelKey="value"
            valueKey="key"
            serviceType="STATIC"
            onChange={(data) => setSelectedYear(data)}
            defaultValue={selectedYear}
            staticData={[
              {
                key: "2019",
                value: 2019,
              },
              {
                key: "2020",
                value: 2020,
              },
              {
                key: "2021",
                value: 2021,
              },
              {
                key: "2022",
                value: 2022,
              },
              {
                key: "2023",
                value: 2023,
              },
              {
                key: "2024",
                value: 2024,
              },
            ]}
          />
        </div>
        {props.type === "SECTION" ? (
          <div className="SelectorContainer">
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(item) => setSelectedClass(item)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
              disabled={!selectedYear}
              // defaultValue={inviteUser.role_id}
            />
          </div>
        ) : null}
      </div>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={createExam}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="formContainer">
          <div className="form-row">
            <div className="input-element">
              <Form.Item
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "Please check your name",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder="Name"
                  name={"email"}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              // loading={isLoading}
              // disabled={!selecte}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
