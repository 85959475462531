import { Outlet } from "react-router";

import "./styles/users.scss";

export default function Students(props) {
  return (
    <div className="Users">
      <Outlet />
    </div>
  );
}
