import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import Typography from "../Typography";
import "./searchbox.scss";
export default function SearchBox(props) {
  return (
    <div className="SearchBox">
      <Input
        style={{ minWidth: 390, height: 42, borderRadius: 12 }}
        placeholder="Search..."
        prefix={
          <SearchOutlined
            className="site-form-item-icon"
            style={{ color: colorPicker("gray_dark"), marginRight: 4 }}
          />
        }
      />
    </div>
  );
}
