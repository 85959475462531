import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";

import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_USER_LIST,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../students.constants";

import "./userList.scss";
import InviteStudents from "./components/InviteStudents";
import UserDetails from "app/shared/UserDetails";
import MoveStudents from "./components/MoveStudents";
import Selector from "app/shared/Selector";
import moment from "moment";
import EditStudent from "./components/EditStudent";

const tableColumn = [
  {
    title: "SID",
    index: "sid",
    search: true,
  },
  {
    title: "Name",
    index: "user_profile.first_name",
    search: true,
  },

  {
    title: "Email",
    index: "email",
  },

  {
    title: "Year",
    index: "class_section.class.year",
    search: true,
  },
  {
    title: "Class",
    index: "class_section.class.name",
    search: true,
  },
  {
    title: "Section",
    index: "class_section.name",
  },

  {
    title: "Account",
    index: "updatedAt",
    handleAction: (propsData) => propsData.handleAction(propsData),
    child: (childProps) =>
      childProps.status === "ACTIVE" || childProps.status === "DISABLED" ? (
        <Popconfirm
          title={`Are you sure want to ${
            childProps.status === "ACTIVE" ? "disable" : "enable"
          } this account`}
          // description="Are you sure to delete this task?"
          onConfirm={() =>
            childProps.handleSwitch(
              childProps.id,
              childProps.status === "ACTIVE"
                ? ACCOUNT_STATUS.DISABLED
                : ACCOUNT_STATUS.ACTIVE
            )
          }
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Switch
            size="small"
            checked={childProps.status === "ACTIVE"}
            disabled={childProps.status === "PENDING"}
          />
        </Popconfirm>
      ) : null,
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "edit_info",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
      adminOnly: true,
    },
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },
    {
      event: "approve",
      icon: <CheckOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#27ae60",
      popup: true,
      popupTitle: "Are you sure want to enable the account?",
      showOn: (data) => data.status !== "ACTIVE",
      adminOnly: true,
    },

    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      adminOnly: true,
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "INVITED",
};

function StudentList(props) {
  const [properties, setProperties] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUsersList();
  }, [activeTab, selectedClass]);
  const fetchUsersList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_USER_LIST + "?type=Student&classId=" + selectedClass
    );
    if (response.status) {
      setUsers(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "view_detail":
        handleShowDrawer("DETAIL");
        setSelectedUser(event.rowData.id);
        return;
      case "edit_info":
        handleShowDrawer("EDIT");
        setSelectedUser(event.rowData);
        return;
      case "approve":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.ACTIVE);
        return;
      case "delete":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.DELETED);
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: userId,
        statusId: status,
      })
    );
    if (response.status) {
      fetchUsersList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleShowModal = (type) => {
    setShowModalFor(type);
    setShowModal(true);
  };

  return (
    <div className="UsersList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Year"
              labelKey="value"
              valueKey="key"
              serviceType="STATIC"
              onChange={(data) => setSelectedYear(data)}
              defaultValue={selectedYear}
              staticData={[
                {
                  key: "2019",
                  value: 2019,
                },
                {
                  key: "2020",
                  value: 2020,
                },
                {
                  key: "2021",
                  value: 2021,
                },
                {
                  key: "2022",
                  value: 2022,
                },
                {
                  key: "2023",
                  value: 2023,
                },
                {
                  key: "2024",
                  value: 2024,
                },
              ]}
            />
          </div>
          <div className="WidgetContainer" style={{ minWidth: 200 }}>
            <Selector
              placeholder="Class"
              labelKey="name"
              valueKey="id"
              serviceType="CLASSES"
              onChange={(data) => setSelectedClass(data)}
              queryFilter={{ key: "year", dataValue: selectedYear }}
            />
          </div>
        </div>
        <div className="rightSelectors">
          {props.userRole && props.userRole.role_name === "Admin" && (
            <>
              <div className="WidgetContainer" style={{ minWidth: 0 }}>
                <Button onClick={() => handleShowModal("MOVE")}>
                  Move Students
                </Button>
              </div>
              <div className="WidgetContainer" style={{ minWidth: 0 }}>
                <Button onClick={() => handleShowModal("INVITE")}>
                  Invite Students
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={users}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          userRole={props.userRole}
        />
      </div>
      <Drawer
        title={showDrawerFor === "EDIT" ? "Edit Student" : "Student Details"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={showDrawerFor === "EDIT" ? 400 : 600}
      >
        {showDrawerFor === "DETAIL" ? (
          <UserDetails selectedUser={selectedUser} />
        ) : (
          <EditStudent
            onCreate={() => {
              setShowDrawer(false);
              fetchUsersList();
            }}
            userInfo={selectedUser}
          />
        )}
      </Drawer>

      <Modal
        title={showModalFor === "INVITE" ? "Create Students" : "Move Students"}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        width={1000}
        footer={null}
      >
        {showModalFor === "INVITE" ? (
          <InviteStudents
            onCreate={() => {
              setShowModal(false);
              fetchUsersList();
            }}
          />
        ) : (
          <MoveStudents
            onCreate={() => {
              setShowModal(false);
              fetchUsersList();
            }}
          />
        )}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.userInfo?.user_role,
});

export default connect(mapStateToProps, {})(StudentList);
