// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Attendance } from ".";
import MarkAttendance from "./components/MarkAttendance";
import StudentList from "./components/StudentList";
//eslint-disable-next-line
export default {
  path: "/attendance/",
  element: <Attendance />,
  strict: true,
  children: [
    {
      path: "/attendance/mark",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <MarkAttendance />
        // </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/attendance/view",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <StudentList />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
