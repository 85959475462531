// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Results } from ".";
import ResultList from "./components/ResultList";
//eslint-disable-next-line
export default {
  path: "/results/",
  element: <Results />,
  strict: true,
  children: [
    {
      path: "/results/list",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <ResultList />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
