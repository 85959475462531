// import ProtectedRoute from "app/utils/ProtectedRoute";
import { Classes } from ".";
import ClassList from "./components/ClassList";
//eslint-disable-next-line
export default {
  path: "/class/",
  element: <Classes />,
  strict: true,
  children: [
    {
      path: "/class/list",
      element: (
        // <ProtectedRoute accessKey="PROPERY_LISTING_VIEsW">
        <ClassList />
        // </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
