import attendanceRoutes from "app/scenes/attendance/attendance.routes";
import classesRoutes from "app/scenes/classes/classes.routes";
import Dashboard from "app/scenes/dashboard";
import examsRoutes from "app/scenes/exams/exams.routes";
import resultsRoutes from "app/scenes/results/results.routes";
import studentsRoutes from "app/scenes/students/students.routes";
import subjectsRoutes from "app/scenes/subjects/subjects.routes";
import usersRoutes from "app/scenes/users/users.routes";
import ProtectedRoute from "app/utils/ProtectedRoute";
import MasterLayout from "./";

export default {
  path: "",
  element: (
    <ProtectedRoute>
      <MasterLayout />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/",
      element: <Dashboard />,
      exact: true,
    },
    usersRoutes,
    studentsRoutes,
    classesRoutes,
    examsRoutes,
    subjectsRoutes,
    resultsRoutes,
    attendanceRoutes
  ],
};
