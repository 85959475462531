import { colorPicker } from "app/utils/helpers/color.helper";
import styled from "styled-components";
const variants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  caption: "p",
  button: "p",
  body: "p",
  subtitle: "p",
  xs: "small",
};

const fontSizes = {
  h1: "96px",
  h2: "60px",
  h3: "48px",
  h4: "34px",
  h5: "24px",
  h6: "20px",
  caption: "14px",
  button: "16px",
  body: "16px",
  subtitle: "26px",
  xs: "12px",
};
const color = {
  "gray-dark": "gray_dark",
  black: "black",
  red: "red",
  "gray-light": "gray_light",
  gray_text: "gray_text",
  white: "white",
  primary: "primary",
};
const Title = styled[variants["body"]]`
  font-size: ${(props) => fontSizes[props.variant || "body"]};
  text-align: left;
  color: ${(props) => colorPicker(color[props.color || "black"])};
  font-weight: ${(props) => props.weight || 500};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export default function Typography(props) {
  return (
    <Title onClick={props.onClick} style={{ ...props.style }} {...props}>
      {props.children}
    </Title>
  );
}
